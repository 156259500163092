<nav class="navbar navbar-expand main-header navbar-dark" style="background-color: var(--background-color)">
    <ul class="navbar-nav">
        <li class="nav-item">
            <a href="javascript:;" data-widget="pushmenu" class="nav-link"><i class="fas fa-bars"
                    style="color:var(--color)"></i></a>
        </li>
    </ul>
    <ul class="navbar-nav" style="text-align:center; color:var(--color);">
        <!-- Centered content can be added here if needed -->
    </ul>
    <ul class="navbar-nav ml-auto">
        <li class="nav-item">
            <a (click)="viewNotification()" href="javascript:;" class="nav-link">
                <i class="fas fa-bell" style="color:var(--color); font-size:18px;"></i>
                <span class="badge badge-danger" *ngIf="notificationCount > 0">{{ notificationCount }}</span>
            </a>
        </li>
        <!-- <li *ngIf="!(loginUser?.role === 'admin') && !(loginUser?.role === 'super-admin') " class="nav-item">
            <a (click)="handleLogout()" href="javascript:;" class="nav-link">
                <i class="fas fa-power-off" style="color:var(--color); font-size:18px;"></i>
            </a>
        </li> -->

        <li *ngIf="(loginUser?.role === 'admin') || (loginUser?.role === 'super-admin') " class="nav-item dropdown">


            <a class="nav-link dropdown-toggle" href="javascript:void(0)"
   id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
    <img *ngIf="loginUser?.profileImage"
         src="https://tejilo.s3.us-east-2.amazonaws.com/images/{{loginUser?.profileImage}}"
         class="brand-image img-circle" style="opacity: .8; height: 25px; width: 25px;" />
    <img *ngIf="!loginUser?.profileImage" src="assets/user-icon.png" class="brand-image img-circle"
         style="opacity: .8; height: 25px; width: 25px;" />
</a>
<div aria-labelledby="dropdownMenuButton1" class="dropdown-menu dropdown-menu-lg dropdown-menu-end">
    <div class="container profile-section mt-3 mb-3">
        <div class="row">
            <div class="col-sm-2">
                <img *ngIf="loginUser?.profileImage"
                     src="https://tejilo.s3.us-east-2.amazonaws.com/images/{{loginUser?.profileImage}}"
                     height="40px" width="40px" class="brand-image img-circle" />
                <img *ngIf="!loginUser?.profileImage" src="assets/user-icon.png" height="40px" width="40px"
                     class="brand-image img-circle" />
            </div>
            <div class="col-sm-10">
                <div class="profile-details">
                    <span class="profile-name fw-bolder mb-4"><b>{{loginUser?.fullName}}</b></span><br>
                    <span class="light-success mt-2">{{loginUser?.role}}</span><br>
                    <span class="profile-profession text-muted">{{loginUser?.email}}</span>
                </div>
            </div>
        </div>
    </div>
    <div class="dropdown-divider"></div>
    <a [routerLink]="['/home/ha-admin/change-password']" routerLinkActive="router-link-active"
       class="dropdown-item">
        <i class="fas fa-lock"></i> Change Password
    </a>
    <div class="dropdown-divider"></div>
    <a href="javascript:void(0)" (click)="handleLogout()" class="dropdown-item">
        <i class="fas fa-sign-out-alt"></i> Logout
    </a>
</div>


        </li>
    </ul>
</nav>

<!-- <p-overlayPanel #op>
    <div class="card scrollable-card">
        <div class="flex align-items-center justify-content-between mb-4">
            <h5>Notifications</h5>
        </div>
        
        <ul *ngIf="!notificationList.length" class="p-0 mx-0 mt-0 mb-4 list-none">
            <li class="text-center text-danger">
                <i class="pi pi-database"></i>
                No Records Found
            </li>
        </ul>

        <ul *ngFor="let notify of notificationList" class="p-0 mx-0 mt-0 mb-4 list-none">
            <li class="flex align-items-center py-2 border-bottom-1 surface-border">
                <div *ngIf="notify.title === 'accepted'"
                    class="w-3rem h-3rem flex align-items-center justify-content-center bg-blue-100 border-circle mr-3 flex-shrink-0">
                    <i class="pi pi-verified text-xl text-blue-500"></i>
                </div>
                <div *ngIf="notify.title === 'request'"
                    class="w-3rem h-3rem flex align-items-center justify-content-center bg-blue-100 border-circle mr-3 flex-shrink-0">
                    <i class="pi pi-user-plus text-xl text-blue-500"></i>
                </div>
                <div *ngIf="notify.title === 'rejected' || notify.title === 'end' "
                    class="w-3rem h-3rem flex align-items-center justify-content-center bg-pink-100 border-circle mr-3 flex-shrink-0">
                    <i class="pi pi-exclamation-triangle text-xl text-pink-500"></i>
                </div>
                <span class="text-900 line-height-3">
                    {{notify.message}}
                    <div class="text-gray-500 text-sm">{{ notify.createdAt | date:'short' }}</div>
                </span>
            </li>
        </ul>
    </div>
</p-overlayPanel> -->



<p-sidebar styleClass="w-25rem" [(visible)]="notificationSidebar" position="right">
    <h4>Notifications</h4>
    <br>
    <ul *ngIf="!notificationList?.length" class="p-0 mx-0 mt-0 mb-4 list-none">
        <li class="text-center text-danger">
            <i class="pi pi-database"></i>
            No Records Found
        </li>
    </ul>
    <ul *ngFor="let notify of notificationList" class="p-0 mx-0 mt-0 mb-4 list-none">
        <li class="flex align-items-center py-2 border-bottom-1 surface-border">
            <div *ngIf="notify?.title === 'accepted'"
                class="w-3rem h-3rem flex align-items-center justify-content-center bg-blue-100 border-circle mr-3 flex-shrink-0">
                <i class="pi pi-verified text-xl text-blue-500"></i>
            </div>
            <div *ngIf="notify?.title === 'request'"
                class="w-3rem h-3rem flex align-items-center justify-content-center bg-blue-100 border-circle mr-3 flex-shrink-0">
                <i class="pi pi-user-plus text-xl text-blue-500"></i>
            </div>
            <div *ngIf="notify?.title === 'rejected' || notify?.title === 'end' "
                class="w-3rem h-3rem flex align-items-center justify-content-center bg-pink-100 border-circle mr-3 flex-shrink-0">
                <i class="pi pi-exclamation-triangle text-xl text-pink-500"></i>
            </div>
            <span class="text-900 line-height-3">
                {{notify?.message}}
                <div class="text-gray-500 text-sm">{{ notify?.createdAt | date:'short' }}</div>
            </span>
        </li>
    </ul>
</p-sidebar>