import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UserRefreshService {
  private refreshSubject = new Subject<void>();
  private sidebarRefreshSubject = new Subject<void>();

  refresh$ = this.refreshSubject.asObservable();
  sidebarRefresh$ = this.sidebarRefreshSubject.asObservable();

  triggerRefresh() {
    this.refreshSubject.next();
  }
  refreshSidebar() {
    this.sidebarRefreshSubject.next();
  }
}
